import { BaseModel } from '@/_models/base'
/* eslint-disable import/no-cycle */
/* eslint-disable class-methods-use-this */
export class LocationAvailabilityOverride extends BaseModel {
  constructor(id, locationId, fromDate, untilDate, reason) {
    super()

    this.id = id
    this.locationId = locationId
    this.fromDate = fromDate
    this.untilDate = untilDate
    this.reason = reason

    this.defaultRoute = 'location-availability-overrides'
  }

  /**
     * Receives json and returns a AvailabilityOverride object
     * @param json
     * @returns LocationAvailabilityOverride
     */
  fromJSON(json) {
    const item = Object.assign(new LocationAvailabilityOverride(), json)
    item.parseBaseJSON()
    item.fromDate = new Date(json.fromDate)
    item.untilDate = new Date(json.untilDate)
    return item
  }
}

export const cLocationAvailabilityOverride = new LocationAvailabilityOverride()
