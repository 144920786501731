/* eslint-disable import/no-cycle */
/* eslint-disable class-methods-use-this */
import { BaseModel } from '@/_models/base'

export class City extends BaseModel {
  constructor(id, name, lon, lat, dateAble) {
    super()

    this.id = id
    this.name = name
    this.lon = lon
    this.lat = lat
    this.dateAble = dateAble

    this.listField = 'cities'

    this.defaultRoute = 'cities'
  }

    static modelName = 'city';

    static modelNamePlural = 'cities';

    fromJSON(json) {
      const item = Object.assign(new City(), json)
      item.parseBaseJSON()
      return item
    }
}

export const cCity = new City()
