/* eslint-disable import/no-cycle */
/* eslint-disable class-methods-use-this */
import { BaseModel } from '@/_models/base'

export class DashboardPreference extends BaseModel {
  constructor(id, employeeId, chats) {
    super()

    this.id = id
    this.employeeId = employeeId
    this.chats = chats

    this.defaultRoute = 'dashboard-preferences'
  }

    static modelName = 'dashboardPreference';

    static modelNamePlural = 'dashboardPreferences';

    fromJSON(json) {
      const item = Object.assign(new DashboardPreference(), json)
      item.parseBaseJSON()
      if (json == null) {
        item.chats = {}
      } else {
        try {
          item.chats = JSON.parse(item.chats)
        } catch (e) {
          item.chats = {}
        }
      }
      item.chats = (typeof item.chats === 'object' && !Array.isArray(item.chats)) ? item.chats : {}
      item.chats.sidebarWidgets = (item.chats.sidebarWidgets) ?? []
      const existing = item.chats.sidebarWidgets.map(widget => widget.name);
      ['userInfo', 'suggestions', 'matches', 'notes', 'actions'].filter(name => !existing.includes(name)).forEach(name => {
        item.chats.sidebarWidgets.push({ name, open: true })
      })
      item.chats.groups = (item.chats.groups) ?? []
      return item
    }
}

export const cDashboardPreference = new DashboardPreference()
