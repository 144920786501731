import router from '@/router'
import { userService } from '@/_services'
import { requests } from '@/_helpers/requests'
import { cPartner } from '@/_models/index'

const partner = JSON.parse(localStorage.getItem('partner'))
// eslint-disable-next-line no-unused-vars
const initialState = partner
  ? { status: { loggedIn: true }, partner }
  : { status: {}, partner: null }

// eslint-disable-next-line import/prefer-default-export
export const authentication = {
  namespaced: true,
  state: {
    status: {
      loggedIn: (partner),
    },
    partner: (partner) || null,
    auth: {},
  },
  getters: {
    location: state => {
      if (state.partner === null) {
        return {}
      }
      const { locations } = state.partner
      if (locations === null || locations.length < 1) {
        return {}
      }
      return locations[0]
    },
  },
  actions: {
    login({ dispatch, commit }, { username, password }) {
      commit('loginRequest', { username })

      // username is email in partner portal. Emails are all lowercase in backend.
      userService.login(username.toLowerCase(), password)
        .then(
          auth => {
            commit('loginSuccess', auth)
            // eslint-disable-next-line no-shadow
            requests.get('partner/me').then(response => {
              commit('updatePartnerSuccess', response.data)
              router.push('/')
            },
            error => {
              commit('loginFailure', error)
              dispatch('alert/error', error, { root: true })
              router.push('/login')
            })
          },
          error => {
            commit('loginFailure', error)
            dispatch('alert/error', error, { root: true })
          },
        )
    },
    logout({ commit }) {
      userService.logout()
      commit('logout')
    },
    checkMe({ dispatch, commit }) {
      requests.get('partner/me').then(response => {
        commit('updatePartnerSuccess', response.data)
      },
      error => {
        commit('loginFailure', error)
        dispatch('alert/error', error, { root: true })
        router.push('/login')
      })
    },
  },
  mutations: {
    loginRequest(state, username) {
      state.status = { loggingIn: true }
      state.username = username
    },
    loginSuccess(state, auth) {
      state.status = { loggedIn: true }
      state.auth = auth
    },
    loginFailure(state) {
      state.status = {}
      state.partner = null
    },
    // eslint-disable-next-line no-shadow
    updatePartnerSuccess(state, partner) {
      state.partner = cPartner.fromJSON(partner)
    },
    changedDp(state, preference) {
      state.partner.dashboardPreference = preference
    },
    logout(state) {
      state.status = {}
      state.partner = null
    },
  },
}
