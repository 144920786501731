/* eslint-disable import/no-cycle */
/* eslint-disable class-methods-use-this */
import { BaseModel } from '@/_models/base'
import { cDashboardPreference } from '@/_models/dashboard-preference'

export class Employee extends BaseModel {
  constructor(name, role, title, password, avatar, email, dashboardPreference) {
    super()

    this.name = name
    this.role = role
    this.title = title
    this.password = password
    this.avatar = avatar
    this.email = email
    this.dashboardPreference = dashboardPreference

    this.defaultRoute = 'employees'
  }

    static modelName = 'employee';

    static modelNamePlural = 'employees';

    fromJSON(json) {
      const item = Object.assign(new Employee(), json)
      item.parseBaseJSON()
      item.dashboardPreference = cDashboardPreference.fromJSON(json.dashboardPreference)
      return item
    }
}
export const employee = {
  Employee,
}

export const cEmployee = new Employee()
