import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'

import { authentication } from './authentication.module'
import { cities } from './cities.module'
import { alert } from './alert.module'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
    authentication,
    cities,
    alert,
  },
  strict: process.env.DEV,
})
