/* eslint-disable import/no-cycle */
/* eslint-disable class-methods-use-this */
import { BaseModel } from '@/_models/base'
import { cLocation } from './index'

export class Partner extends BaseModel {
  constructor(id, username, locations) {
    super()

    this.id = id
    this.username = username
    this.locations = locations

    this.defaultRoute = 'employees'
  }

    static modelName = 'employee';

    static modelNamePlural = 'employees';

    fromJSON(json) {
      const item = Object.assign(new Partner(), json)
      item.parseBaseJSON()
      const locations = []
      if (!item.locations || item.locations.length !== 0) {
        item.locations.forEach(locatoin => {
          locations.push(cLocation.fromJSON(locatoin))
        })
      }
      item.locations = locations
      return item
    }
}

export const cPartner = new Partner()
