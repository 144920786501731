import {
  BaseModel, cLocation, cLocationTimeSlot,
} from './index'

export class Deal extends BaseModel {
  constructor(id, location, locationId, locationOverrideName, name, tips, price, profit, cost, dealTypeId,
    dealType, assignable, deprecatedAt, matchCount, availability, needsReservation) {
    super()

    this.id = id
    this.location = location
    this.locationId = locationId
    this.locationOverrideName = locationOverrideName
    this.name = name
    this.tips = tips
    this.price = price
    this.profit = profit
    this.cost = cost
    this.dealTypeId = dealTypeId
    this.dealType = dealType
    this.assignable = assignable
    this.deprecatedAt = deprecatedAt
    this.matchCount = matchCount
    this.availability = availability
    this.needsReservation = needsReservation

    this.defaultRoute = 'deals'
  }

  static modelName = 'deal';

  static modelNamePlural = 'deals';

  fromJSON(json) {
    const item = Object.assign(new Deal(), json)
    item.parseBaseJSON()
    item.location = (json.location) ? cLocation.fromJSON(json.location) : null
    item.timeSlots = cLocationTimeSlot.fromJsonList(json.timeSlots)
    return item
  }
}

export const cDeal = new Deal()
