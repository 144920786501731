import authHeader from './auth-header'

const apiUrl = process.env.VUE_APP_API_URL

function handleResponse(response) {
  return response.text().then(text => {
    const data = text && JSON.parse(text)
    if (!response.ok) {
      if (response.status === 401) {
        // Todo Show unauthorizes snackbar
      }

      const error = (data && data.message) || response.statusText
      return Promise.reject(error)
    }

    return data
  })
}

function dataRequest(url, payload, method) {
  const requestOptions = {
    method,
    headers: { ...authHeader(), ...{ 'Content-Type': 'application/json' } },
    body: JSON.stringify(payload),
  }

  return fetch(`${apiUrl}${url}`, requestOptions)
    .then(handleResponse)
    .then(data => data)
}

function get(url) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  }

  return fetch(`${apiUrl}${url}`, requestOptions)
    .then(handleResponse)
    .then(data => data)
}

function getBlob(url) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  }

  return fetch(`${apiUrl}${url}`, requestOptions)
    .then(response => response.blob())
    .then(data => data)
}

function post(url, payload) {
  return dataRequest(url, payload, 'POST')
}

function upload(url, data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: data,
  }

  return fetch(`${apiUrl}${url}`, requestOptions)
    .then(handleResponse)
    // eslint-disable-next-line no-shadow
    .then(data => data)
}

function put(url, payload) {
  return dataRequest(url, payload, 'PUT')
}

function deleteReq(url, payload) {
  return dataRequest(url, payload, 'DELETE')
}

export const requests = {
  get,
  getBlob,
  post,
  put,
  deleteReq,
  upload,
  handleResponse,
}

export default requests
