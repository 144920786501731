/* eslint-disable import/no-cycle */
/* eslint-disable class-methods-use-this */

import { BaseModel } from '@/_models/base'

export class Contact extends BaseModel {
  constructor(id, gender, firstName, identifier, lastName, email, phone, address, postalCode, city, accountNumber, accountName, country) {
    super()

    this.id = id

    this.gender = gender
    this.firstName = firstName
    this.identifier = identifier
    this.lastName = lastName
    this.email = email
    this.phone = phone
    this.address = address
    this.postalCode = postalCode
    this.city = city
    this.accountNumber = accountNumber
    this.accountName = accountName
    this.country = country

    this.defaultRoute = 'contacts'
  }

  methods() {
    return this.withDefaultMethods({
      detailed: 'contacts/:id',
    })
  }

  fromJSON(json) {
    const item = Object.assign(new Contact(), json)
    item.parseBaseJSON()
    return item
  }

  getFullName() {
    return `${this.firstName} ${this.lastName}`
  }
}

export const cContact = new Contact()
