import { requests } from '@/_helpers/requests'

const apiUrl = process.env.VUE_APP_API_URL

function login(username, password) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ username, password }),
  }

  return fetch(`${apiUrl}auth/login?type=partner`, requestOptions)
    .then(requests.handleResponse)
    .then(auth => {
      // login successful if there's a jwt token in the response
      if (auth && auth.data) {
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        localStorage.setItem('auth', JSON.stringify(auth.data))
      }
      return auth
    })
}

function logout() {
  localStorage.removeItem('auth')
}

// eslint-disable-next-line import/prefer-default-export
export const userService = {
  login,
  logout,
}
