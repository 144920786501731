/* eslint-disable */

export const alert = {
    namespaced: true,
    state: {
        error: null,
        errorCount: 0
    },
    getters: {
        error: state => state
    },
    actions: {
        error({commit}, error) {
          commit('updateError', error)
        }
    },
    mutations: {
        updateError(state, error) {
            state.error = error;
            state.errorCount ++;
        },
    }
};

