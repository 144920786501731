/* eslint-disable import/no-cycle */
/* eslint-disable class-methods-use-this */
/* eslint-disable no-param-reassign */
import { BaseModel } from '@/_models/base'
import { requests } from '@/_helpers/requests'

export class Photo extends BaseModel {
  constructor(id, original, resized, hash, ordering) {
    super()

    this.id = id
    this.original = original
    this.resized = resized
    this.hash = hash
    this.ordering = ordering

    this.defaultRoute = 'photos'
  }

  fromJSON(json) {
    const item = Object.assign(new Photo(), json)
    item.parseBaseJSON()
    return item
  }

  withStoragePath(full) {
    const path = (full) ? this.original : this.resized
    if (path?.includes('googleapis.com')) {
      return path
    }
    return `https://storage.googleapis.com/${path}`
  }

  create(data) {
    if (!data) {
      data = this
    }
    return new Promise((resolve, reject) => {
      requests.post(this.getMethod('create'), data).then(response => {
        this.id = response.data
        resolve()
      }, error => {
        window.console.log(error)
        reject(error)
      })
    })
  }
}

export const cPhoto = new Photo()
