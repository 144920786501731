/* eslint-disable */
import { cCity } from '@/_models/index';

export const cities = {
    namespaced: true,
    state: {
        list: []
    },
    getters: {
        cities: state => state.list
    },
    actions: {
        getAll({ commit }) {
            commit('getAllRequest');

            cCity.getAll()
                .then(
                    data => commit('getAllSuccess', data),
                    error => commit('getAllFailure', error)
                );
        }
    },
    mutations: {
        getAllRequest(state) {
            state.loading = true;
        },
        getAllSuccess(state, data) {
            state.list = data;
            state.loading = false;
        },
        getAllFailure(state, error) {
            state.error = error;
        }
    }
};

